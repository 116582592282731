/* v8 ignore start */
import { writable } from "svelte/store";

type Media<Query extends Record<string, string> = Record<string, string>> = {
  [K in keyof Query]?: boolean | string;
};

type MediaQueryLists = Record<string, MediaQueryList>;

function calculateMedia(mqls: MediaQueryLists) {
  const media: Media = {};
  for (const name in mqls) {
    media[name] = mqls[name].matches;
  }
  return media;
}

export function createMedia<Query extends Record<string, string>>(
  queries: Query,
) {
  return writable<Media<Query> | undefined>(undefined, (set) => {
    const mqls: MediaQueryLists = {};
    const updateMedia = () => set(calculateMedia(mqls));

    if (typeof window === "undefined") {
      return;
    }

    for (const key in queries) {
      mqls[key] = window.matchMedia(queries[key]);
      mqls[key].addEventListener("change", updateMedia);
    }
    updateMedia();
    return () => {
      for (const key in mqls) {
        mqls[key].removeEventListener("change", updateMedia);
      }
    };
  });
}
/* v8 ignore stop */
