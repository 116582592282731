export const media = {
  xs: "(min-width: 480px)",
  sm: "(min-width: 540px)",
  md: "(min-width: 640px)",
  lg: "(min-width: 780px)",
  xl: "(min-width: 960px)",
  "2xl": "(min-width: 1280px)",
  "3xl": "(min-width: 1560px)",
  portrait: "(orientation:portrait)",
  darkmode: "(prefers-color-scheme: dark)",
  touch: "(pointer:coarse)",
};
